<template>
  <section class="data-list-container">

    <div class="mb-5">
      <new-button :to="{name:'addNewBanner'}" class="mr-5">Adauga banner nou</new-button>
    </div>

    <vx-card>
      <v-data-table
        :headers="headers"
        :hide-default-footer="true"
        :items="paginatedItems.data"
        @update:sort-by="updateSortField"
        @update:sort-desc="updateSortType"
      >

        <template v-slot:item.actions="{item}">
          <edit-button :to="{ name:'banner-management-edit', params: { id: item.id} }"></edit-button>
          <delete-button @delete="deleteItem(item)"></delete-button>
        </template>

        <template v-slot:footer>
          <v-pagination
            :length="paginatedItems.last_page"
            :total-visible="5"
            :value="currentPage"
            color="#76bd43"
            @input="changePage"
          ></v-pagination>
        </template>

      </v-data-table>
    </vx-card>

  </section>
</template>

<script>
import NewButton from '@/components/general-form/NewButton'
import EditButton from '@/components/general-form/EditButton'
import DeleteButton from '@/components/general-form/DeleteButton'
export default {
  name: 'bannerList',
  components: {DeleteButton, EditButton, NewButton},
  data () {
    return {
      headers: [
        {text: 'ID', value: 'id', sortable: false},
        {text: 'Titlu', value: 'title', sortable: false},
        {text: 'Subtitlu', value: 'subtitle', sortable: false, align: 'center'},
        {text: 'Descriere', value: 'description', sortable: false, align: 'center'},
        {text: '', value: 'actions', width: '150', sortable: false}
      ],
      sortFiled: [],
      sortIsDesc: []
    }
  },
  computed: {
    totalItems () {
      return this.paginatedItems.length
    },
    paginatedItems () {
      return this.$store.getters['banners/paginatedData']
    },
    currentPage () {
      return this.$store.getters['banners/currentPage']
    },
    sortParam () {
      if (this.sortFiled.length) {
        const sortString = []
        for (let i = 0; i < this.sortFiled.length; i++) {
          sortString.push((this.sortIsDesc[i] === true ? '-' : '') + this.sortFiled[i])
        }
        return sortString.join(',')
      }
      return ''
    }
  },
  methods: {
    changePage (newPage) {
      this.$store.dispatch('banners/changePage', newPage)
    },
    updateSortField (field) {
      this.sortFiled = field
    },
    updateSortType (isDesc) {
      this.sortIsDesc = isDesc
    },
    updateFilter (searchValue, filterName) {
      if (searchValue === '') {
        this.$store.dispatch('banners/deleteFilter', filterName)
      } else {
        this.$store.dispatch('banners/addFilter', {filterName, searchValue})
      }
    },
    loadItems () {
      this.$store.dispatch('banners/loadItems')
    },
    deleteItem (item) {
      this.$store.dispatch('banners/deleteItem', item.id)
    }
  },
  watch: {
    sortParam (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$store.dispatch('banners/changeSort', newValue)
      }
    }
  },
  created () {
    this.loadItems()
  }
}
</script>

<style scoped>

</style>
